<i18n>
ru:
  gram: г
  kilogram: кг
  liter: л
  piece: шт
  portion: 'порция | порций'
ua:
  gram: г
  kilogram: кг
  liter: л
  piece: шт
  portion: 'порций | порция | порций'
us:
  gram: g
  kilogram: kg
  liter: l
  piece: 'item | items'
  portion: 'portions | portion | portions'
</i18n>

<template>
  <common-tweened-number
    :num="quantity ?? 0"
    :type="type"
  />&nbsp;{{ measureComputed }}
</template>

<script setup lang="ts">
import { ProductMeasureType } from '~api/consts'

import type { NumericType } from '~types/common'

const { quantity = ProductMeasureType.Undefined, measure = 0 } = defineProps<{
  quantity?: number
  measure?: ProductMeasureType
}>()

const { translate } = useI18nSanitized()

const type = computed<NumericType>(() => {
  switch (measure) {
    case ProductMeasureType.Kilogram:
      return 'nutritional'
    case ProductMeasureType.Liter:
      return 'nutritional'
    case ProductMeasureType.Piece:
      return 'int'
    case ProductMeasureType.Portion:
      return 'int'
    default:
      return 'nutritional'
  }
})
const measureComputed = computed<string>(() => {
  switch (measure) {
    case ProductMeasureType.Kilogram:
      return translate('quantityMeasure.kilogram', null, quantity)
    case ProductMeasureType.Liter:
      return translate('quantityMeasure.liter', null, quantity)
    case ProductMeasureType.Piece:
      return translate('quantityMeasure.piece', null, quantity)
    case ProductMeasureType.Portion:
      return translate('quantityMeasure.portion', null, quantity)
    default:
      return translate('quantityMeasure.gram', null, quantity)
  }
})
</script>
